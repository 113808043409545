import Vue from 'vue'
import Vuex from './vuex'
import _Echo from 'laravel-echo'
import _ from 'lodash'

class Echo
{
	constructor() {
		this.token 				= null
		this._echo 				= null
	}

    EchoInstance() {
		this.token 				= new Vuex().getters['auth/token']

        if(this.token) {
            return new _Echo({
                broadcaster: 	'pusher',
                key:            process.env.VUE_APP_WEBSOCKET_APP_KEY,
                wsHost:         process.env.VUE_APP_WEBSOCKET_APP_HOST,
                wsPort:         +process.env.VUE_APP_WEBSOCKET_APP_PORT,
                wssPort:        +process.env.VUE_APP_WEBSOCKET_APP_PORT,
                forceTLS:  		process.env.VUE_APP_WEBSOCKET_APP_ENCRYPTED === 'true',
                encrypted:      process.env.VUE_APP_WEBSOCKET_APP_ENCRYPTED === 'true',
                cluster:        process.env.VUE_APP_WEBSOCKET_APP_CLUSTER,
				disableStats:   false,
				enabledTransports: ['ws', 'wss'],
				authEndpoint: 	`${process.env.VUE_APP_WEBSOCKET_URL}/api/broadcasting/auth`, 
				auth: {
					headers: {
						Authorization: `Bearer ${ this.token }`,
						Accept:        'application/json',
					}
                }
            })
        }

        console.log('Houve um erro ao iniciar o ECHO.')

        return null
    }

	RunGlobal() {
		if(!this._echo) {
			this._echo 				= this.EchoInstance()
		}

		if(this._echo) {
			this._echo.join('presence-global')
				.here((accounts) => {
					new Vuex().dispatch('system/ResetOnlineAccounts', accounts)
					new Vuex().dispatch('system/GetOnlineAccounts')
				})
				.joining((account) => {
			        new Vuex().dispatch('system/AddOnlineAccount', account)
					new Vuex().dispatch('system/GetOnlineAccounts')
				})
				.leaving((account) => {
					new Vuex().dispatch('system/RemOnlineAccount', account)
				})
			    .error((error) => {
			        console.error(error);
			    });
		} else {
	    	console.log('Houve um erro ao iniciar o ECHO global.')
	    } 
	}
}

export default Echo