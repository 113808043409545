<template>
	<div class="container">
		<div class="row flex-column align-items-center justify-content-between">
			<div class="col-12 mx-auto py-md-2 text-md-center max-500">
				<div class="d-block">
					<span>Template</span>
					<h4 class="font-24 font-md-32 m-0">
						Movimentação de templates
					</h4>
				</div>
			</div>
			<div class="col-12 col-md mt-3">
				<div class="row justify-content-between">
					<div class="col-12 col-md-6">
						<!-- Pesquisa -->
						<div class="form-group position-relative mb-0">
							<input type="search"
								placeholder="Buscar"
								class="form-control border-no overflow-hidden pl-5"
								v-model="search"
							>
							<i class="fa fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center" aria-hidden="true"></i>
						</div>
						<div class="card rounded bg-light border-no overflow-hidden">
							<transition name="fade" mode="out-in">
								<div class="scroll-this px-3 px-md-5 pb-3 pb-md-5">
									<template v-if="source_schemas.length > 0">
										<div 
											v-for="schema in source_schemas"
											:key="schema.schema"	
											class="select-card with-border mt-3"
										>
											<div class="list-item">
												<div class="col px-0">
													<p class="m-0">
														{{ schema.provider }}
													</p>
													<p class="m-0">
														<small><b>TIPO: </b> {{ schema.type }} </small>
													</p>
													<p class="m-0">
														<small><b>ORG: </b> {{ schema.name }} </small>
													</p>
													<p class="m-0">
														<small><b>SCHEMA: </b> {{ schema.schema }} </small>
													</p>
												</div>
												<div class="col-auto d-flex flex-column align-items-end px-0 buttons-container">
													<button class="btn p-2 btn-sm btn-success" @click="Setsource(schema)">Origem</button>
													<button class="mt-2 btn p-2 btn-sm btn-info" @click="SetDestiny(schema)">Destino</button>
												</div>
											</div>
										</div>
									</template>
									<template v-else>
										<div class="select-card with-border mt-3">
											<label>
												Nenhum resultado encontrado
											</label>
										</div>
									</template>
								</div>
							</transition>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="col-12 d-flex justify-content-end">
							<button
								class="btn btn-primary"
								content="Migrar em massa"
								v-tippy="{ arrow: true }"
								data-toggle="modal" data-target="#modal-massive-migration-start"
							>
								<i class="fal fa-sync font-18"></i>
							</button>
						</div>
						<!-- <p class="my-4 mx-5 font-weight-bold text-dark font-20">Dados da movimentaçao</p> -->
						<div class="">
							<div class="col form-group">
								<label>Origem</label>
								<input type="text" class="form-control" v-model="source.schema">
							</div>
							<div class="col form-group">
								<label>Destino</label>
								<input type="text" class="form-control" v-model="destiny.schema" placeholder="Ex: template_medico">
							</div>
							<div v-if="(destiny_is_pj)" class="providers-container p-3">
								<p class="mb-2">Colaboradores:</p>
								<div class="providers">
									<div class="d-inline-block form-group mr-4 mb-0 form-check">
										<input 
											id="select_all"
											class="form-check-input"
											type="checkbox"
											v-model="select_all"
										>
										<label 
											class="form-check-label"
											for="select_all"
										>
											SELECIONAR TODOS
										</label>
									</div>
									<div
										v-for="provider in providers"
										:key="provider.account_provider_id"
										class="d-inline-block form-group mr-4 mb-0 form-check"
									>
										<input 
											:id="`provider-${provider.account_provider_id}`"
											type="checkbox"  
											class="form-check-input"
											v-model="provider.selected"
										>
										<label 
											class="form-check-label"
											:for="`provider-${provider.account_provider_id}`"
										>
											{{ provider.provider.name }}
										</label>
									</div>
								</div>
							</div>
							<div class="col form-group">
								<div class="d-inline-block form-group mr-4 mb-0 form-check">
									<input 
										type="checkbox"  
										id="with_restore_point"
										v-model="params.with_restore_point"
									>
									<label for="with_restore_point">
										Ponto de Restauração
									</label>
								</div>
							</div>
							<div v-if="both_is_pj" class="col form-group">
								<div class="d-inline-block form-group mr-4 mb-0 form-check">
									<input 
										type="checkbox"  
										id="library"
										v-model="params.library"
									>
									<label for="library">
										Biblioteca
									</label>
								</div>
								<div v-if="!destiny_is_integration" class="d-inline-block form-group mr-4 mb-0 form-check">
									<input 
										type="checkbox"  
										id="proceedings"
										v-model="params.proceedings"
									>
									<label for="proceedings">
										Procedimentos
									</label>
								</div>
							</div>
							<div class="col d-flex justify-content-between">
								<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modal-history">
									histórico
								</button>
								<button 
									type="button" 
									class="btn btn-primary"
									:class="{
										'disabled': !source.schema || !destiny.schema 
									}"

									@click="Show()"
								>
									mover 
									<i class="fa fa-play" aria-hidden="true"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> 
		<div class="modal fade" id="modal-massive-migration-start" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<p class="py-3 mx-5 font-weight-bold text-center text-dark font-20">Migração em Massa</p>

					<div class="modal-body mx-5 pb-3">
						<p>
							Deseja mesmo realizar a migração em massa? <b>Essa ação é irreversível!</b>
						</p>
						<p>
							A origem dos dados será do schema '<b>wk051267707adb - BASE DE TESTE</b>'
						</p>

						<div class="col-12 px-0 mt-5 d-flex justify-content-start align-content-center">
							<div class="form-group d-flex align-items-center">
								<input
									type="radio"
									id="massive_migration_type_now"
									value="now"
									v-model="massive_migration.type"
								>
								<label for="massive_migration_type_now" class="px-1">Iniciar agora</label>
							</div>
							<div class="form-group d-flex align-items-center mx-3">
								<input
									type="radio"
									id="massive_migration_type_schedule"
									value="schedule"
									v-model="massive_migration.type"
								>
								<label for="massive_migration_type_schedule" class="px-1">Agendar</label>
							</div>
						</div>
						
						<div class="col-12 px-0 d-flex justify-content-start align-content-center">
							<div v-if="massive_migration.type === 'schedule'" class="col-12 px-0 d-flex justify-content-start align-content-center">
								<div class="form-group d-flex align-items-center">
									<input
										id="massive_migration_datetime"
										type="datetime-local"
										class="form-control"
										v-model="massive_migration.datetime"
									>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer p-2">
						<div class="col-12 px-0 d-flex justify-content-end">
							<button
								class="btn btn-light mr-3"
								data-dismiss="modal"
							>
								Cancelar
							</button>
							<button
								class="btn btn-primary"
								@click="MassiveDataMigrate()"
							>
								{{ massive_migration.type === 'schedule' ? 'Agendar' : 'Iniciar' }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="modal-history" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<p class="py-3 mx-5 font-weight-bold text-center text-dark font-20">Histórico</p>

					<div class="modal-body mx-5 pb-3">
						<div class="col-12 px-0 scroll-x scroll-transparent d-flex">
							<ul class="nav nav-pills nav-theme w-md-100 justify-content-start mb-4 px-0">
								<li class="nav-item">
									<a class="nav-link pb-md-3 px-md-4 active" data-toggle="tab" href="#default">Padrão</a>
								</li>
								<li class="nav-item">
									<a class="nav-link pb-md-3 px-md-4" data-toggle="tab" href="#massive" @click="GetMassiveDataMigrateLogs()">Em massa</a>
								</li>
							</ul>
						</div>

						<div class="tab-content d-flex justify-content-center">
							<div id="default" class="col-12 px-0 mb-5 tab-pane active">
								<div class="scroll-this">
									<div v-for="log in history_logs.default" :key="log.id">
										<div class="card rounded px-3 py-2 mb-2 font-14 text-dark">
											<div class="form-row">
												<div class="col">
													<p class="m-0"><b>ORIGEM:</b> {{ log.origin }}</p>
													<p class="m-0"><b>DESTINO:</b> {{ log.destiny }}</p>
													<p class="m-0"><b>BIBLIOTECA:</b> {{ log.library ? 'SIM' : 'NÃO' }}</p>
													<p class="m-0"><b>PROCEDIMENTOS:</b> {{ log.proceedings ? 'SIM' : 'NÃO' }}</p>
													<p class="m-0"><b>AUTOR:</b> {{ log.user.name }}</p>
												</div>
												<div class="col-auto">
													{{ ParseDate(log.created_at) }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="massive" class="col-12 px-0 mb-5 tab-pane">
								<div v-if="history_logs.massive.length" class="scroll-this">
									<div v-for="log in history_logs.massive" :key="log.id">
										<div class="card rounded pr-3 mb-2 font-14 text-dark card-status"
											:class="{
												'status-scheduled': log.status === 'scheduled',
												'status-running': log.status === 'running',
												'status-completed': log.status === 'completed',
												'status-failed': log.status === 'failed',
											}">
											<div class="form-row">
												<div class="col">
													<p class="m-0">
														<b>ID: </b> {{ log.job_id }}
													</p>
													<p class="m-0">
														<b>STATUS: </b> 
														{{
															log.status === 'running' ? 'Executando' :
															log.status === 'completed' ? 'Concluído' : 'Falhou'
														}}
													</p>
													<p class="m-0">
														<b>INÍCIO: </b> {{ ParseDate(log.job_start_at) }}
													</p>
													<p class="m-0">
														<b>AUTOR: </b> {{ log.user.name }}
													</p>
													<p v-if="log.has_faileds_logs" class="m-0 with-error">
														<b>LOG ERROS: </b>
														<a class="copy-link" @click="CopyToClipboard(log.failed_logs)">
															Copiar
														</a>
													</p>
												</div>
												<div class="col-auto">
													{{ ParseDate(log.updated_at) }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-else class="col-12 px-0 d-flex align-items-center justify-content-center">
									<p class="text-center">
										Não há registro de migração em massa
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import { mapActions } from 'vuex' 
import Helpers from '../../helpers'
const helpers 		= new Helpers()

export default {
	data() {
		return {
			search: 	'',
			source: 	{},
			destiny: 	{},
			params: 	{
				library: false,
				proceedings: false,
				with_restore_point: false
			},
			schemas: 	[],
			history_logs: 		{
				default: 	[],
				massive: 	[]
			},
			massive_migration: {
				type: 'now',
				datetime: null
			},
			providers:	[],
			select_all: false,
			medical_content_schema: process.env.VUE_APP_MEDICAL_CONTENT_SCHEMA
		}
	},
	computed: {
		destiny_is_pj() {
			return this.destiny.type === 'PJ'
		},
		destiny_is_integration() {
			if(this.destiny.schema) {
				return this.destiny.schema.substring(0, 2).toLowerCase() === 'in'
			}
			
			return false
		},
		both_is_pj() {
			return this.source.type === 'PJ' && this.destiny_is_pj
		},
		source_schemas() {
			let search = helpers.RemoveAcento(this.search).toLowerCase()

			return this.schemas.filter( schema => {
				if(search) {
					let found 		= false 
					found  			= helpers.RemoveAcento(schema.name).toLowerCase().includes(search)

					if(!found) found	= helpers.RemoveAcento(schema.provider).toLowerCase().includes(search)

					if(!found) found 	= helpers.RemoveAcento(schema.schema).toLowerCase().includes(search)

					if(!found) found 	= helpers.RemoveAcento(schema.type).toLowerCase().includes(search)
 
					if(!found) found 	= helpers.RemoveAcento(schema.account_id).toLowerCase().includes(search)
					return found
				} else {
					return true
				}
			})
		}
	},
	watch: {
		source: {
			handler(val) {
				this.params.library 	= false
				this.params.proceedings = false
				this.select_all 		= false

				if(this.destiny_is_pj) {
					this.providers 		= this.GetProviders()
				} else {
					this.providers 		= []
				}
			}
		},
		destiny: {
			handler(val) {
				this.params.library 	= false
				this.params.proceedings = false
				this.select_all 		= false

				if(this.destiny_is_pj) {
					this.providers 		= this.GetProviders()
				} else {
					this.providers 		= []
				}
			}
		},
		select_all: {
			handler(val) {
				window.jQuery(".form-check-label").each(function () {
					const input = window.jQuery(this).prev()

					const label = window.jQuery(this)

					if(input.prop("checked") !== val) {
						label.click()
					}
				})
			}
		},
		'massive_migration.type': {
			handler(val) {
				if(val === 'schedule') {
					this.massive_migration.datetime = moment().format('YYYY-MM-DDTHH:mm')
				} else {
					this.massive_migration.datetime = null
				}
			}
		},
		'massive_migration.datetime': {
			handler(val) {
				if(val) {
					if(moment(val).format('YYYY-MM-DDTHH:mm') < moment().format('YYYY-MM-DDTHH:mm')) {
						Swal.fire({
							title:		'Data e hora inválida',
							icon:		'error',
							toast: 		true,
							position: 	'top',
							timer: 		5000,
							timerProgressBar: true,
						})
					}
				}
			}
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		async GetSchemas() {
			const vm = this;

			vm.StartLoading()

			await window.api.call('get', 'get-schemas')
				.then(({data}) => { 
					if(!data.response) {
						Swal.fire({
							title:		'Eita!',
							text:		'Houve um erro: ' + data.message ?? null,
							icon:		'error'
						})
					}

					this.schemas = window._.orderBy(data.schemas, [ (e) => e.name ], 'asc')
					this.history_logs.default = data.logs
				}).finally(() => {
                    this.FinishLoading()
                })
		},
		Show() {
			if(!this.source.schema || !this.destiny.schema) return

			if(this.destiny.schema === this.medical_content_schema) {
				Swal.fire({
					title:		'Ops!',
					text:		'Não é possível mover para o schema de conteúdo médico!',
					icon:		'error'
				})

				return
			}

			Swal.fire({
				title: 'Voce tem certeza?',
				icon: 'warning',
				showConfirmButton: true,
				confirmButtonText: 'Sim, mover',
				showCancelButton: true,
				cancelButtonText: 'Cancelar',
				html: `Deseja mesmo realizar esta movimentaçao?<br> <b>${this.source.schema}</b> → <b>${this.destiny.schema}</b><br>Esta ação é irreversível.`
			}).then((result) => {
				if(result.isConfirmed) {
					const vm = this;

					vm.StartLoading()

					const providers = this.providers
						.filter(p => p.selected)
						.map(p => p.schema ? p.schema : p.workgroup_schema)

					window.api.call('post', 'update-default-template', {
						source: this.source,
						destiny: this.destiny,
						params: this.params,
						providers
					})
						.then(({data}) => { 
							if(!data.response) {
								Swal.fire({
									title:		'Eita!',
									text:		'Houve um erro: ' + data.message ?? null,
									icon:		'error'
								})
								return
							}

							this.GetSchemas();

							Swal.fire({
								toast: true,
								html: 'Template movimentado com sucesso...',
								icon: 'success',
								timer: 5000,
								position: 'top'
							})
						}).finally(() => {
							this.FinishLoading()
						})
				}
			})
		},
		ParseDate(date)
		{
			if(date) {
				return moment(date).format('DD/MM/Y H:mm')
			}

			return ''
		},
		HandleItem(item) { 
			Swal.fire({
				text: 'Este template deve o destino ou origem?',
				showDenyButton: 		true,
				denyButtonText: 		'Origem',
				showConfirmButton: 		true,
				confirmButtonText: 		'Destino',
				showCloseButton: 		true,
			}).then((confirm) => {
				if(confirm.value === true) {
					this.movement.destiny = item.schema
				} else if(confirm.value === false) {
					this.movement.source = item.schema
				}
			})
		},
		MassiveDataMigrate() {
			const vm = this;

			if(vm.massive_migration.datetime && moment(vm.massive_migration.datetime).format('YYYY-MM-DDTHH:mm') < moment().format('YYYY-MM-DDTHH:mm')) {
				Swal.fire({
					title:		'Data e hora inválida',
					icon:		'error',
					toast: 		true,
					position: 	'top',
					timer: 		5000,
					timerProgressBar: true,
				})
				
				return;
			}

			vm.StartLoading()

			window.api.call('post', '/massive-data-migrate', {
				...vm.massive_migration
			})
				.then(({data}) => { 
					if(!data.response) {
						Swal.fire({
							title:		'Eita!',
							text:		data.message || 'Houve um erro ao realizar a migração em massa!',
							icon:		'error'
						})

						return
					}

					Swal.fire({
						toast: true,
						title: data.message,
						icon: 'success',
						timer: 5000,
						position: 'top',
						timerProgressBar: true,
					})
				}).finally(() => {
					vm.FinishLoading()

					window.jQuery('#modal-massive-migration-start').modal('hide')
				})
		},
		Setsource(schema) {
			this.source = schema
		},
		SetDestiny(schema) {
			if(schema.schema === this.medical_content_schema) return;

			this.destiny = schema
		},
		GetProviders() {
			this.StartLoading()

			window.api.call('post','/get-account-management', {
					account_id: this.destiny.account_id,
					is_integration_pj: this.destiny_is_integration
				})
				.then(async ({data}) => {

					if(data.response)
					{
						this.providers  				= data.account.provider.map(el => { 
							return {
								...el, selected: false
							}
						})
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		GetMassiveDataMigrateLogs() {
			this.StartLoading()

			window.api.call('get', '/massive-data-migrate-logs')
				.then(async ({data}) => {
					if(!data.response) {
						Swal.fire({
							title:		'Erro ao obter o histórico de migração em massa',
							icon:		'error',
							toast: 		true,
							position: 	'top',
							timer: 		5000,
							timerProgressBar: true,
						})

						return
					}

					this.history_logs.massive = data.logs.map(log => {
						const has_faileds_logs = log.failed_logs ? JSON.parse(log.failed_logs) !== null : false;
						
						return {
							...log,
							has_faileds_logs
						}
					})
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		async CopyToClipboard(text) {
			try {
				if(!text) return;

				await navigator.clipboard.writeText(text);
			} catch (err) {
				console.error('Failed to copy: ', err);
			}
		}
	},
	beforeMount() {
		this.GetSchemas();
	}
}
</script>

<style lang="css" scoped>
	#modal-massive-migration-start .modal-dialog {
		max-width: 610px;
	}

	#modal-massive-migration-start .modal-body {
		min-height: 230px;
	}

	#modal-history .modal-body {
		min-height: 630px;
	}

	.card.rounded {
		border-radius: 10px !important;
	}

	.card.rounded.card-status {
		border: 1px solid var(--hr);
		overflow: hidden;
		padding-left: 11px;
	}

	.card.rounded.card-status .form-row {
		border-radius: 5px 0 0 5px !important;
		border: 1px solid #fff;
		z-index: 2;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
	}

	.card-status.status-scheduled::after {
		content: '';
		background-color: #000df3;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 10px;
		height: 100%;
	}

	.card-status.status-running::after {
		content: '';
		background-color: #eebd09;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 10px;
		height: 100%;
	}

	.card-status.status-completed::after {
		content: '';
		background-color: #008218;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 10px;
		height: 100%;
	}

	.card-status.status-failed::after {
		content: '';
		background-color: #FF0000;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 10px;
		height: 100%;
	}

	.providers-container {
		border: 2px solid var(--hr);
		border-radius: 8px;
		margin: .75rem 1rem;
	}

	.providers-container .providers {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.copy-link {
		cursor: pointer;
		text-decoration: underline !important; 
	}

	.with-error {
		color: #9b0808;
	}

	.list-item {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.4rem;
		padding: 0.9rem;
	}

	.list-item .buttons-container button {
		width: 70px;
	}
</style>